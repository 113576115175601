import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Paper,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Modal,
    Card,
    CardHeader,
    IconButton,
    CardContent,
    CardActions,
    Divider,
    Box,
    Pagination,
    CircularProgress,
    Autocomplete,
    TableSortLabel,
    Tabs, Tab ,
    Collapse,

} from '@mui/material';
import CustomDatePicker from '../common/customdatepicker';
import dayjs from 'dayjs';
import apiService from '../../services/actions';
import uploadIcon from "../../assets/images/uploadIcon.svg";
import uploadIcon2 from "../../assets/images/uploadIcon2.svg";
import eyeIcon from "../../assets/images/eyeIcon.svg";
import downloadIcon from "../../assets/images/downloadIcon.svg";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import CloseIcon from '@mui/icons-material/Close';
import CustomDateTimePicker from '../common/customdatetimepicker';
import { formatDateToMMDDYYYY, formatToCurrentDateTime, formateDateToDDMMYYYYHHMM ,convertUTCToLocal} from '../common';
import { toast } from 'react-toastify';
import moment from 'moment';
import PatientSearch from '../common/patientSearch';
import { useLocation, useNavigate } from 'react-router-dom';
import reportSent from '../../assets/images/tick.svg';
import reportNotSent from '../../assets/images/x-tick.jpg';
import axios from 'axios';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CompletedOrders = () => {
    const fileInputRef = useRef(null);
    const inputRef = useRef(null);
const[externalLabConfirmId,setexternalLabConfirmId ]=useState(null)
const [resetKey, setResetKey] = useState(0);
    const location = useLocation();
    const receivedFilter = location.state;
    let s_token = localStorage.getItem('access_token');
    const [eclinicData, seteclinicData] = useState([""]);
    const [hospitalId, setHospitalId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
    const [uploadReportsModalOpen, setUploadReportsModalOpen] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedLabReport, setSelectedLabReport] = useState(null);
    const [typedLabReport, setTypedLabReport] = useState('');
    const [loadinModal, setModalLoading] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [viewModalData, setViewModalData] = useState(null);
    const [sampleCollectionModal, setSampleCollectionModal] = useState(false);
    const [viewLabTests, setViewLabTests] = useState(false);
    const [labTestDetails, setLabTestDetails] = useState([]);
    const [sampleSelectionRecord, setSampleCollectionRecord] = useState([]);
    const [page, setPage] = useState(1);
        const [expanded, setExpanded] = useState(-1);
        const [activeTest, setactiveTest] = useState();
        const [bookiIdModal, setbookiIdModal] = useState(false);
    
    const itemsPerPage = 10;
    const [editProcedureStatus, setEditProcedureStatus] = useState(null);
    const [editReportsUpload, setEditReportsUpload] = useState(null);
    const [labTestsOrderReports, setLabTestsOrderReports] = useState([])
    const [patientLabTestsOrderDetails, setPatientLabTestsOrderDetails] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([]);
    const loginObjStr = localStorage.getItem('loginObj');
    const [patientSearch, setpatientSearch] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [labList, setLablist] = useState([]);
    const [logView, setlogView] = useState('')
    const [logData, setlogData] = useState([])
    const [formData, setFormData] = useState(
        {
            homeSampleCollectionDateTime: '',
            labTestDateTime: '',
            techniciansAssigned: '',
            contactNumber: '',
            status: '',
        }
    );
    const manualUploadValidation =async (order) =>{
        setSelectedOrder(order);
    
        let payLoad ={
            orderId:order.orderId
        }
        const res = await apiService.validManualId(payLoad);
    console.log(res)
    if(res.data){
        if(res.data.successObject == 1){
            setbookiIdModal(true)
        }
        else{
            toast.error("Please try after sometime"
            )
        }
    
    }
       }
    const [sort, setSort] = useState({
        sortBy: 'orderId',
        sortOrder: 'asc',
    });
    const statusList = [
        { id: 0, name: "", status: "Active" },

        { id: 1, name: "Sample Collected", status: "Active" },
        { id: 2, name: "Sample ready to dispatch", status: "Active" },
        { id: 3, name: "Sample Shipped", status: "Active" },
        { id: 4, name: "Sample - in transit", status: "Active" },
        { id: 5, name: "Sample Delivered to Lab", status: "Active" },
        { id: 6, name: "Empty Sample box Shipped", status: "Active" },
        { id: 7, name: "Empty Sample box - in transit", status: "Active" },
        { id: 8, name: "Empty Sample box returned", status: "Active" },
        { id: 9, name: "Sample ready to E-clinic", status: "Active" }
      ];
    const loginObj = JSON.parse(loginObjStr);
    const userCode = loginObj?.userSession?.userCode || null;
    const filePath = `${process.env.REACT_APP_IMAGE_URL}`;
    let currentOrder = null;
    const isPDF = viewModalData?.photoName ? viewModalData?.photoName?.toLowerCase().endsWith('.pdf')
        : viewModalData?.reportInvoiceDocName ? viewModalData?.reportInvoiceDocName?.toLowerCase().endsWith('.pdf') : false;

    const parseLocationData = (locationData) => {
        try {
            const data = JSON.parse(locationData);
            const { hospitalId, locationId } = data;
            setHospitalId(hospitalId);
            setLocationId(locationId);
        } catch (error) {
            console.error('Error parsing location data:', error);
        }
    };
    const [selectedTab, setSelectedTab] = useState(0);
  const confirmOrder = async (orderDetails) => {
        setLoading(true);
        try {
            const recordIndex = getDataForPage(page).findIndex((record) => record.orderId === orderDetails.orderId);
     
            if (recordIndex !== -1) {
                const updatedOrderDetails = {
                    ...orderDetails,
                    procedureStatus: 1,
                    technicianAssigned: formData[recordIndex]?.techniciansAssigned,
                    technicianContactNo: formData[recordIndex]?.contactNumber,
                    confirmDateTime: moment(formData[recordIndex]?.confirmDateTime)?.format("YYYY-MM-DD HH:mm:ss"),
                    collectionSampleDate: formData[recordIndex]?.homeSampleCollectionDateTime ? moment(formData[recordIndex]?.homeSampleCollectionDateTime)?.format("YYYY-MM-DD HH:mm:ss") : activeTest?.preferDateTime ? moment(activeTest.preferDateTime)?.format("YYYY-MM-DD HH:mm:ss") : '',
                    preferDateTime: formData[recordIndex]?.labTestDateTime ? moment(formData[recordIndex]?.labTestDateTime)?.format("YYYY-MM-DD HH:mm:ss") : activeTest?.collectionSampleDate ? moment(activeTest.collectionSampleDate)?.format("YYYY-MM-DD HH:mm:ss") : '',
                    testLabDateTime: formData[recordIndex]?.homeSampleCollectionDateTime ? moment(formData[recordIndex]?.homeSampleCollectionDateTime)?.format("YYYY-MM-DD HH:mm:ss") : activeTest?.preferDateTime ? moment(activeTest.preferDateTime)?.format("YYYY-MM-DD HH:mm:ss") : '',
                    testLabDateTime1: formData[recordIndex]?.homeSampleCollectionDateTime ? moment(formData[recordIndex]?.homeSampleCollectionDateTime)?.format("YYYY-MM-DD HH:mm:ss") : activeTest?.preferDateTime ? moment(activeTest.preferDateTime)?.format("YYYY-MM-DD HH:mm:ss") : '',
                    collectionStatus: 1,
                };
                const confirmOrderResponse = await apiService.patientLabTestsOrder(updatedOrderDetails, 'PUT');
                setLoading(false);

                if (confirmOrderResponse?.status === 200 && confirmOrderResponse?.data) {
                    if (confirmOrderResponse.data) {
                        toast.success("Order updated successfully.", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        setOrderDetails([]);
                        handleSearch();
                    } else {
                        toast.error("Please try after sometime", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            } else {
                setLoading(false);
                toast.error("Order details not found.", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            setLoading(false);
            console.error("Error confirming order:", error);
        }
    };
    const manualBookingSave =async ()=>{
        let payLoad={
             orderId:selectedOrder.orderId,
             externalLabConfirmId: externalLabConfirmId
         }
         const res = await apiService.updateManualId(payLoad);
         console.log(res.data)
         if(res.data){
             if(res.data.successObject == 1){
                 setbookiIdModal(false);
                 setSelectedOrder({});
                 setexternalLabConfirmId(null)
                 handleSearch();
                 toast.success("Updated Successfully")   
                  }
             else{
                 toast.error("Please try after sometime"
                 )
             }
         
         }
         }
    const getEclinics=async (e)=>{
 
        // try {
    let eclinicname = e ? e : 'bam'
          const response = await axios.get(
            `${process.env.REACT_APP_COMMON_URL}EclinicAddress/list?eclinicName=${eclinicname}`,
            { headers: { 'Authorization': `Bearer ${s_token}` } }
        );
          if (response?.data?.data) {
            console.log(response, 'eclinicData')
            seteclinicData(response.data.data);
       
          } 
        // } catch (error) {
        //   console.error("Error fetching data:", error);
        // }
      }; 
    useEffect(() => {
        getLabList()
        getEclinics()
        const locationData = localStorage.location;
        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            parseLocationData(locationData);
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }
    }, []);

    const togglePSEditMode = (order) => {
      if(order.status == 2){
        setEditProcedureStatus((prevMode) => (prevMode === order.orderId ? null :order.orderId));
        }
        else{
            toast.error("Please select report upload.", {
                position: toast.POSITION.TOP_CENTER,
            });        
    };    };

    const toggleRUEditMode = (orderId) => {
        setEditReportsUpload((prevMode) => (prevMode === orderId ? null : orderId));
    };
    const getlogDeatails = async (orderId) => {;
      let s_token = localStorage.getItem('access_token')
        setlogData([])
const payload = {

    "orderId": orderId
};
         const postResponse = await axios.post(
                `${process.env.REACT_APP_COMMON_URL}lab/sampleStatus/list`,
                payload,
                { headers: { 'Authorization': `Bearer ${s_token}` } }
            );
    
            console.log(postResponse);
    
            if (postResponse?.status == 200) {
             setlogData(postResponse.data)
            }
            
            else {
                console.log(postResponse)
            }
    };
    const getCollectionStatus = (i) => {
        const statusMap = {
            1: 'Order Confirmed',
            // 2: 'Test@Lab',
            3: 'Technician Assigned',
            4: 'Sample Collected',
            5: 'Cancelled by Patient',
            6: 'Cancelled by Diagnostic',
        };

        return statusMap[i] || '';
    }
    const handleClosepatientSearch = (e) => {
        console.log(e)
        setSelectedPatient(e)
        setpatientSearch(false)
        handleSearch(e)
    }
    const updateObjectAtIndex = (index, updatedObject) => {
        setFormData((prevArray) => {
            // Create a copy of the array to avoid mutating state directly
            const newArray = [...prevArray];
            newArray[index] = updatedObject;
            return newArray;
        });
    }
  
    const handleUploadClick = async (order) => {
           if(!order.extLabConfirmId){
                    return toast.error('Please Enter Manual BookingID.')
                }
        setSelectedOrder(order);
        setLoading(true);

        const labTestsOrderPayload = {
            orderId: order?.orderId,
            status: 1,
            docmentRequired: 'Y'
        };
        const patientLabTestsOrderPayload = {
            patientLabTestOrderId: order?.orderId
        };

        try {
            const labTestsOrderReportResponse = await apiService.getLabTestsOrderReport(labTestsOrderPayload);

            if (labTestsOrderReportResponse?.status === 200 && labTestsOrderReportResponse?.data) {
                setLabTestsOrderReports(labTestsOrderReportResponse?.data);
            }
        } catch (error) {
            console.error('Error fetching lab tests order report:', error);
        }

        try {
            const patientLabTestsOrderDetailsResponse = await apiService.getPatientLabTestsOrderDetails(patientLabTestsOrderPayload);

            if (patientLabTestsOrderDetailsResponse?.status === 200 && patientLabTestsOrderDetailsResponse?.data) {
                setPatientLabTestsOrderDetails(patientLabTestsOrderDetailsResponse?.data);
            }
        } catch (error) {
            console.error('Error fetching patient lab tests order details:', error);
        }
        setLoading(false)
        setModalOpen(true)
    }
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const handleKeyDown = (e) => {
        // Allow only numbers
        const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
        if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
          e.preventDefault();
        }
      };
    const handleDownloadClick = async (rec) => {
           if (rec?.photoName) {
               const fileExtension = rec.type?.toLowerCase();
       
               // For PDF and other downloadable formats
               if (fileExtension === 'pdf' || fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
                   try {
                       const response = await fetch(filePath + rec.photoName);
                       if (!response.ok) throw new Error("Failed to fetch the file");
       
                       const blob = await response.blob();
                       const url = window.URL.createObjectURL(blob);
                       const a = document.createElement('a');
                       a.href = url;
                       a.download = rec.orderId+'- Report'; // Use the original filename
                       document.body.appendChild(a);
                       a.click();
                       document.body.removeChild(a);
                       window.URL.revokeObjectURL(url);
                   } catch (error) {
                       toast.error("Failed to download the file. Please try again later.", {
                           position: toast.POSITION.TOP_CENTER,
                       });
                   }
               }
               // For Excel files
               else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                   window.open(filePath + rec.photoName, '_blank');
               }
               // For unsupported formats
               else {
                   toast.error(`Unsupported file format: ${fileExtension}`, {
                       position: toast.POSITION.TOP_CENTER,
                   });
               }
           } else {
               toast.error("Download is not available at the moment. Please try after sometime.", {
                   position: toast.POSITION.TOP_CENTER,
               });
           }
       };

    const handleUploadInVoiceClick = (order) => {
        currentOrder = order;
        fileInputRef.current.click();
    };

    const uploadInvoice = async (e, orderDetails) => {
        if (e?.target?.files && e.target.files.length > 0) {
            setLoading(true)
            const selectedFile = e.target.files[0];
            const base64Strings = await encodeBase64Files(selectedFile);

            if (selectedFile && currentOrder) {
                const updatedOrderDetails = {
                    ...currentOrder,
                    reportInvoiceDoc: base64Strings?.[0],
                    reportInvoiceDocName: currentOrder?.reportInvoiceDocName ? currentOrder?.reportInvoiceDocName : selectedFile.name,
                    reportInvoiceDocType: selectedFile?.type?.split('/')?.[1],
                };

                const uploadInvoiceResponse = await apiService.patientLabTestsOrder(updatedOrderDetails, 'PUT');
                setLoading(false)
                if (uploadInvoiceResponse?.status === 200 && uploadInvoiceResponse?.data) {
                    if (uploadInvoiceResponse.data) {
                        toast.success("Your file Uploaded successfully.", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        handleSearch();
                    }
                    else {
                        toast.error("Please try after sometime", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }

            }
            currentOrder = null;
        }
    };


    const handleReceiptClick = async (order) => {
        setSelectedOrder(order);
        setLoading(true);

        const patientLabTestsOrderPayload = {
            patientLabTestOrderId: order?.orderId
        };

        try {
            const patientLabTestsOrderDetailsResponse = await apiService.getPatientLabTestsOrderDetails(patientLabTestsOrderPayload);

            if (patientLabTestsOrderDetailsResponse?.status === 200 && patientLabTestsOrderDetailsResponse?.data) {
                setPatientLabTestsOrderDetails(patientLabTestsOrderDetailsResponse?.data);
            }
        } catch (error) {
            console.error('Error fetching patient lab tests order details:', error);
        }
        setLoading(false);
        setInvoiceModalOpen(true);
    }

    const calculateTotalPrice = () => {
        if (!patientLabTestsOrderDetails) {
            return 0;
        }
        const totalPrice = patientLabTestsOrderDetails.reduce((total, test) => total + Number(test.amount || 0), 0);
        return Number(totalPrice.toFixed(2));
    };


    const calculateTotalDiscount = () => {
        if (!patientLabTestsOrderDetails) {
            return 0;
        }
        const totalDiscount = patientLabTestsOrderDetails.reduce((sum, test) => sum + Number(test.discountAmount || 0), 0);
        return Number(totalDiscount.toFixed(2));
    };

    const calculatePayableAmount = () => {
        const totalPrice = calculateTotalPrice();
        const totalDiscount = calculateTotalDiscount();
        return Math.max(totalPrice - totalDiscount, 0);
    };

    const handleDownload = () => {
        window.print();
    };

    const handleViewClick = (rec) => {
        if (rec?.reportInvoiceDocName)
            window.open(filePath + rec.reportInvoiceDocName, '_blank');
    };
    const handleViewClick2 = (rec) => {
        if (rec?.photoName)
            window.open(filePath + rec.photoName, '_blank');
    };
    
    const [searchFilters, setSearchFilters] = useState({
        orderId: '',
        patientId: '',
        fromDate: dayjs().subtract(7, 'day'),
        toDate: dayjs(),
        procedureStatus: 2,
        uploadStatus: undefined,
        labPartner : receivedFilter?.labName ? receivedFilter?.labName  : '',


    });

    const handleSearch = async (e) => {
        setPage(1)
        setLoading(true);
        let hospitalIdlocalCopy = null;
        let locationIdlocalCopy = null;
        const locationData = localStorage?.location;
        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            try {
                const data = JSON.parse(locationData);
                const { hospitalId, locationId } = data;
                setHospitalId(hospitalId);
                setLocationId(locationId);
                hospitalIdlocalCopy = hospitalId;
                locationIdlocalCopy = locationId;
            } catch (error) {
                console.error('Error parsing location data:', error);
            }
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }
        const payload = {
            procedureStatus: selectedTab ,
            fromDate1: searchFilters?.fromDate ? formatDateToMMDDYYYY(searchFilters?.fromDate) : undefined,
            toDate1: searchFilters?.toDate ? formatDateToMMDDYYYY(searchFilters?.toDate) : undefined,
            orderId: searchFilters?.orderId ? searchFilters.orderId : undefined,
            patientId:e?.code != undefined ? e?.code:searchFilters?.patientId ? searchFilters.patientId : undefined,
            patientName:searchFilters?.patientName ? searchFilters?.patientName : undefined,
            // hospitalId: hospitalIdlocalCopy ? hospitalIdlocalCopy : hospitalId ? hospitalId : undefined,
            locationId: locationIdlocalCopy ? locationIdlocalCopy : locationId ? locationId : undefined,
            // status: searchFilters.uploadStatus,
            hospitalId:searchFilters?.hospitalName ? searchFilters.hospitalName : undefined,
            labPartner: searchFilters.labPartner ? searchFilters.labPartner : undefined,
            consignmentId : searchFilters.consignmentId  ?  searchFilters.consignmentId  : undefined,
            sampleStatusId : searchFilters.sampleStatusId  ?  searchFilters.sampleStatusId  : undefined ,
            diagnosticType:'COLLECTION CENTER',
            patientName:searchFilters.patientName ? searchFilters.patientName :undefined,
            CHEId:searchFilters.CHEId  ?  searchFilters.CHEId  : undefined,
            testType:  searchFilters.testType  ?  searchFilters.testType  : undefined,

        };
        if(payload.labPartner){
            payload.status =  undefined
        }
        try {
            const ordersDetailsResponse = await apiService.getOrderDetails(payload);
            setLoading(false);
            if (ordersDetailsResponse.status === 200 && ordersDetailsResponse?.data) {
                setOrderDetails(ordersDetailsResponse.data)
            } else {
                console.error('API error:', ordersDetailsResponse.statusText);
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    };
    const getLabList=async ()=>{
        const res1 = await apiService.getCCList();
        console.log(res1)
        setLablist(res1.data)

    }
    const handleClear = () => {
        let labData ={...labList}
        // setLablist([])
   
        setSearchFilters(prevState => ({ ...prevState,     orderId: '',
            patientId: '',
                      patientName:'',
                      fromDate: dayjs().subtract(7, 'day'),
                      toDate: dayjs(),
                      procedureStatus: 1,
                      uploadStatus: undefined,
                      CHEId : undefined,
                      hospitalName: '', 
                       labPartner:'',
                       failure:null,
                       extLabConfirmIdStatus:null,
                       sampleStatusId: 0,
                       consignmentId: '',
                       testType: null
        }));
            // setLablist(labData);
            setResetKey((prevKey) => prevKey + 1);

    if (inputRef.current) {
        inputRef.current = {};
        
    }}
//     useEffect(() => {
     
//         if(searchFilters.hospitalName == ''){
//             // setSearchFilters(prevState => ({ ...prevState,     labPartner: '',
 
//             // }));
// handleSearch()
// }
//     }, [ searchFilters]); 
    useEffect(() => {
        handleSearch();
       
    }, [modalOpen,selectedTab]);

    const getDataForPage = useCallback((page) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const dataForPage = orderDetails.slice(startIndex, endIndex);
        return dataForPage;
    }, [orderDetails, itemsPerPage]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getSortedData = () => {
        return getDataForPage(page).sort((a, b) => {
            const aValue = a[sort.sortBy];
            const bValue = b[sort.sortBy];

            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return sort.sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
            } else {
                const compareResult = aValue?.localeCompare(bValue);
                return sort.sortOrder === 'asc' ? compareResult : -compareResult;
            }
        });
    };

    useEffect(() => {
        getDataForPage(page);
    }, [page, getDataForPage,modalOpen]);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleCloseInvoiceModal = () => {
        setInvoiceModalOpen(false);
    };

    const ProcedureStatusDropdown = ({ currentStatus, orderId, updateOrderStatus }) => {
        const [status, setStatus] = useState(currentStatus);

        const handleChange = async (event) => {
            const newStatus = event.target.value;
            setStatus(newStatus);

            const updatedOrder = orderDetails?.find(order => order.orderId === orderId);

            if (updatedOrder) {
                updatedOrder.procedureStatus = newStatus;

                try {
                    const procedureStatusUpdate = await apiService.patientLabTestsOrder(updatedOrder, 'PUT');
                    if (procedureStatusUpdate?.status === 200 ) {
                        // if (procedureStatusUpdate.data?.[0]) {
                            toast.success("Procedure Status Updated Successfully", {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        // }
                        // else {
                        //     toast.error("Please try after sometime", {
                        //         position: toast.POSITION.TOP_CENTER,
                        //     });
                        // }
                    }
                    updateOrderStatus(orderId, newStatus);
                    handleSearch();
                } catch (error) {
                    console.error("Failed to update order status:", error);
                             toast.error("Please try after sometime", {
                                position: toast.POSITION.TOP_CENTER,
                            });
                }
            }
        };

        return (
            <Select variant="standard" value={status} onChange={handleChange} size="small">
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Completed</MenuItem>
            </Select>
        );
    };

    const ReportUploadStatusDropdown = ({ currentStatus, orderId, updateOrderStatus }) => {
        const [status, setStatus] = useState(currentStatus);

        const handleChange = async (event) => {
            setLoading(true)
            const newStatus = event.target.value;
            setStatus(newStatus);
            const updatedOrder = orderDetails?.find(order => order.orderId === orderId);

            if (updatedOrder) {
                updatedOrder.status = newStatus;

                try {
                    const uploadStatusResponse = await apiService.patientLabTestsOrder(updatedOrder, 'PUT');
                    if (uploadStatusResponse?.status === 200 && uploadStatusResponse?.data) {
                        if (uploadStatusResponse.data) {
                            toast.success("Upload Status Updated Successfully", {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                        else {
                            toast.error("Please try after sometime", {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }
                    }
                    updateOrderStatus(orderId, newStatus);
                    setTimeout(() => {
                        handleSearch();
                    }, 1000);
                } catch (error) {
                    console.error("Failed to update report upload status:", error);
                }
                finally {
                    setLoading(false)
                }
            };

        };
        return (
            <Select variant="standard" value={status} onChange={handleChange} size="small">
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Uploaded</MenuItem>
                <MenuItem value={0}> Cancelled</MenuItem>
            </Select>
        );
    }

    const handleDeleteClick = async (rec) => {
        setLoading(true);
        const currentDateTime = formatToCurrentDateTime(new Date());
        const payload = [{
            createdBy: userCode,
            createdDate: currentDateTime,
            id: rec?.id,
            labOrderDoc: rec?.labOrderDoc ? rec?.labOrderDoc : "",
            labTestCode: rec?.labTestCode,
            labTestName: rec?.labTestName,
            modifiedBy: userCode,
            modifiedDate: currentDateTime,
            orderId: selectedOrder?.orderId,
            status: 0,
            type: rec?.type,
        }];

        try {
            const deleteLabReportResponse = await apiService.uploadLabReports(payload, 'PUT');

            if (deleteLabReportResponse?.status === 200 && deleteLabReportResponse?.data) {
                if (deleteLabReportResponse.data?.[0]) {
                    toast.success("Your file Deleted successfully.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setLabTestsOrderReports([]);
                    const labTestsOrderPayload = {
                        orderId: selectedOrder?.orderId,
                        status: 1,
                        docmentRequired: 'Y'
                    };

                    try {
                        const labTestsOrderReportResponse = await apiService.getLabTestsOrderReport(labTestsOrderPayload);

                        if (labTestsOrderReportResponse?.status === 200 && labTestsOrderReportResponse?.data) {
                            setLabTestsOrderReports(labTestsOrderReportResponse?.data);
                        }
                    } catch (error) {
                        console.error('Error fetching lab tests order report:', error);
                    }
                }
                else {
                    toast.error("Please try after sometime", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        } catch (error) {
            console.error('Error Deleting the Lab Test Record', error);
        }
        setLoading(false);
    }
    const handleFieldChange2 = (fieldName, value) => {
        const valu1 = value.replace(/[^0-9]/g, '');

      setexternalLabConfirmId(valu1)
    };
    const updateOrderStatus = (orderId, newStatus) => {
        console.log(orderId, newStatus)
        // Make an API call to update the order status here
        // Example:
        // apiService.updateOrderStatus(orderId, newStatus)
        //   .then((response) => {
        //       // Handle success
        //   })
        //   .catch((error) => {
        //       // Handle error
        //   });
    };

    const handleCollectionStatus = (order) => {
        setSampleCollectionModal(true);
        setSampleCollectionRecord(order);
    }

    const handleFieldChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };

    const onSampleCollectionClose = () => {
        setSampleCollectionModal(false);
        setSampleCollectionRecord([])
        setFormData('');
    };

    const onSampleCollectionSave = async () => {
        setLoading(true);
        try {
            const updatedOrderDetails = {
                ...sampleSelectionRecord,
                procedureStatus: 1,
                technicianAssigned: formData?.techniciansAssigned,
                technicianContactNo: formData?.contactNumber,
                confirmDateTime: formData?.labTestDateTime ? moment(formData?.labTestDateTime)?.format("YYYY-MM-DD HH:mm:ss") : undefined,
                collectionSampleDate: formData?.homeSampleCollectionDateTime ? moment(formData?.homeSampleCollectionDateTime)?.format("YYYY-MM-DD HH:mm:ss") : undefined,
                collectionStatus: formData?.status,
            };
            const confirmOrderResponse = await apiService.patientLabTestsOrder(updatedOrderDetails, 'PUT');
            setLoading(false);

            if (confirmOrderResponse?.status === 200 && confirmOrderResponse?.data) {
                if (confirmOrderResponse.data) {
                    toast.success("Order updated successfully.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setOrderDetails([]);
                    setSampleCollectionModal(false);
                    setSampleCollectionRecord([])
                    setFormData('');
                    handleSearch();
                } else {
                    toast.error("Please try after sometime", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        } catch (error) {
            setLoading(false);
            console.error("Error confirming order:", error);
        }
    };

    const handleOrderClick = (order) => {
        setViewLabTests(true);
        setLabTestDetails(order);
    };

    const onCloseLabTestsView = () => {
        setbookiIdModal(false)
        setViewLabTests(false);
        setLabTestDetails([]);
    }

    const uploadReportsModalClose = () => {
        setSelectedFiles([]);
        setUploadReportsModalOpen(false)
    };

    const handleInputChange = (event, newInputValue) => {
        setTypedLabReport(newInputValue);
    };

    const handleAutocompleteChange = (event, newValue) => {
        setSelectedLabReport(newValue);
    };

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setSelectedFiles([...selectedFiles, ...newFiles]);
    };

    const handleRemoveFile = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
    };

    const encodeBase64Files = async (files) => {
        if (!Array.isArray(files)) {
            files = [files];
        }

        const base64Array = await Promise.all(
            files.map((file) => {
                return new Promise((resolve) => {
                    let reader = new FileReader();
                    reader?.readAsDataURL(file);
                    reader.onload = () => {
                        resolve(reader.result.split(",")[1]);
                    };
                });
            })
        );

        return base64Array;
    };
    const handleCancelView=async (e,orderId)=>{
        if(e== true){
        const res = await getlogDeatails(orderId)}
    
        setlogView(e)
       }
       const exportToExcel = () => {

              const exportData = orderDetails.map((item) => ({
                 "Order ID": item.orderId,
                 'Patient': item.patientName,
                 "Diagnostic Name": item.hospitalName,
                 "Revenue Source": item.sourceChannel,
                 "eClinic Name": item.chehospitalName,
                 "eClinic Location": item.chelocationName,
                 "Procedure Status":  item.procedureStatus === 2
                   ? 'Completed'
                   : item.procedureStatus === 1
                       ? 'Pending'
                       : item.procedureStatus === 4
                           ? 'Cancelled'
                           : item.procedureStatus,
                           'Report Upload' :   item.status === 2
                               ? 'Uploaded'
                               : item.status === 1
                                   ? 'Pending'
                                   : item.status === 0 ? 'Cancelled' : item.status,
                 "Lab ConfirmID": item.extLabConfirmId,
                 "LabConfirmID Status": item.extLabConfirmIdStatus == 'M' ? 'Manual' : item.extLabConfirmId ? 'Auto' : '',
                 "Total Amount (INR)": item.paidAmount,
                 "Consignment Id": item.consignmentId,
                 "Consignment Date": item.consignmentDate ? convertUTCToLocal(item.consignmentDate,"MM/DD/YYYY hh:mm:ss") : '',
                 "Sample Status": item.sampleStatus == 'Unknown Status' ? '' : item.sampleStatus,
               }));
               const worksheet = XLSX.utils.json_to_sheet(exportData);
               const workbook = XLSX.utils.book_new();
               XLSX.utils.book_append_sheet(workbook, worksheet, "TableData");
           
               // Write and save the Excel file
               const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
               const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
               saveAs(dataBlob, "TableData.xlsx");
             };
    const handleSave = async (e) => {
        if(selectedFiles.length == 0  ){
            return   toast.error("Please select file", {
                position: toast.POSITION.TOP_CENTER,
            });
        }        const base64Strings = await encodeBase64Files(selectedFiles);
        const currentDateTime = formatToCurrentDateTime(new Date());
        const isTypedValue = selectedLabReport === null;

        const typedLabTestCode = typedLabReport;
        const typedLabTestName = typedLabReport;

        const selectedLabTestReport = patientLabTestsOrderDetails.find((report) => report.labTestName === selectedLabReport);
        const selectedLabTestCode = selectedLabTestReport ? selectedLabTestReport.labTestName : null;
        // const selectedLabTestCode = selectedLabTestReport ? selectedLabTestReport.labTestCode : null;
        const selectedLabTestName = selectedLabTestReport ? selectedLabTestReport.labTestName : null;
        ;
        const payload = selectedFiles.map((file, index) => ({
            createdBy: userCode,
            createdDate: currentDateTime,
            labOrderDoc: base64Strings[index],
            labTestCode: isTypedValue ? typedLabTestCode : selectedLabTestCode,
            labTestName: isTypedValue ? typedLabTestName : selectedLabTestName,
            modifiedBy: userCode,
            modifiedDate: currentDateTime,
            orderId: selectedOrder?.orderId,
            status: 1,
            type: file?.type?.split('/')?.[1],
        }));
        payload.forEach((element)=>{
            element.type = element.type == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'? 'xlsx' : element.type == 'vnd.ms-excel' ? 'xls' : element.type
        })
        const uploadlabOrderReportResponse = await apiService.uploadLabReports(payload, 'POST');
        setLoading(true)
        if (uploadlabOrderReportResponse.status === 200 && uploadlabOrderReportResponse?.data) {
            toast.success("Your file uploaded successfully.", {
                position: toast.POSITION.TOP_CENTER,
            });
            let data ={
                "patientId": selectedOrder?.patientId,
                "orderId":selectedOrder?.orderId,
                "share_type": "LU",
                "sourcechannel":"ECLINIC",
                "createdBy":userCode,
                "modifiedBy":userCode
            }
            const res = await apiService.uploadLabReportsLog(data);
            console.log(res)

            setLabTestsOrderReports([]);
            const labTestsOrderPayload = {
                orderId: selectedOrder?.orderId,
                status: 1,
                docmentRequired: 'Y'
            };

            try {
                const labTestsOrderReportResponse = await apiService.getLabTestsOrderReport(labTestsOrderPayload);

                if (labTestsOrderReportResponse?.status === 200 && labTestsOrderReportResponse?.data) {
                    setLabTestsOrderReports(labTestsOrderReportResponse?.data);
                }
            } catch (error) {
                console.error('Error fetching lab tests order report:', error);
            }
            setSelectedFiles([]);

        }
        setLoading(false)


        setUploadReportsModalOpen(false);
    };
    const handleExpandClick = (index, data) => {
        setactiveTest(data)
        console.log(data)
        setExpanded(expanded === index ? -1 : index);
        let payload = {
            homeSampleCollectionDateTime: data.preferDateTime ? data.preferDateTime : '',
            labTestDateTime: data.collectionSampleDate ? data.collectionSampleDate : '',
        }
        updateObjectAtIndex(index, payload)
    };
    const handleSort = (column) => {
        // If the same column is clicked, toggle the sort order
        const newOrder = sort.sortBy === column && sort.sortOrder === 'asc' ? 'desc' : 'asc';
        setSort({
            sortBy: column,
            sortOrder: newOrder,
        });
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '2rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem', marginBottom: '1rem' }}>
                <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: '600' }}>
                    Collection Centre Orders
                </Typography>
            </div>
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999, display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color="secondary" />
            </div>
            <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
                          <Grid container spacing={2}>
                              <Grid item xs={2}>
                                  <TextField
                                      label="Order ID"
                                      variant="outlined"
                                      fullWidth
                                      value={searchFilters.orderId}
                                      size='small'
                                      onChange={(e) => setSearchFilters({ ...searchFilters, orderId: e.target.value, labPartner:'' })}
                                  />
                              </Grid>
                              <Grid item xs={2}>
                                  <TextField
                                      label="Patient ID"
                                      variant="outlined"
                                      fullWidth
                                      size='small'
                                      value={searchFilters.patientId}
                                      onChange={(e) => setSearchFilters({ ...searchFilters, patientId: e.target.value, labPartner:'' })}
                                  />
                              </Grid>
                              <Grid item xs={2}>
                                  <TextField
                                      label="Patient Name"
                                      variant="outlined"
                                      fullWidth
                                      size='small'
                                      value={searchFilters.patientName}
                                      onChange={(e) => setSearchFilters({ ...searchFilters, patientName: e.target.value, labPartner:'' })}
                                  />
                              </Grid>
                              <Grid item xs={2}>
                                  <CustomDatePicker
                                      label="From Date"
                                      value={searchFilters.fromDate}
                                      onChange={(date) => setSearchFilters({ ...searchFilters, fromDate: date , labPartner:'' })}
                                  />
                              </Grid>
                              <Grid item xs={2}>
                                  <CustomDatePicker
                                      label="To Date"
                                      value={searchFilters.toDate}
                                      onChange={(date) => setSearchFilters({ ...searchFilters, toDate: date , labPartner:'' })}
                                  />
                              </Grid>
                            
                        
                              <Grid item xs={2}>
                                  <FormControl fullWidth variant="outlined">
                                      <InputLabel size='small' >Upload Status</InputLabel>
                                      <Select
                                          label="Upload Status"
                                          value={searchFilters.uploadStatus}
                                          size='small'
                                          onChange={(e) => setSearchFilters({ ...searchFilters, uploadStatus: e.target.value , labPartner:'' })}
                                      >
                                          <MenuItem value={0}>Cancelled</MenuItem>
                                          <MenuItem value={1}>Pending</MenuItem>
                                          <MenuItem value={2}>Uploaded</MenuItem>
                                      </Select>
                                  </FormControl>
                              </Grid>
                              <Grid item xs={2}>
                                  <FormControl fullWidth variant="outlined">
                                      <InputLabel size='small' >LabConfirm Status</InputLabel>
                                      <Select
                                          label="LabConfirm Status"
                                          value={searchFilters.extLabConfirmIdStatus}
                                          size='small'
                                          onChange={(e) => setSearchFilters({ ...searchFilters, extLabConfirmIdStatus: e.target.value, failure :null })}
                                      >
                                          <MenuItem value={'M'}>Manual</MenuItem>
                                          <MenuItem value={'A'}>All</MenuItem>
                                      </Select>
                                  </FormControl>
                               
                              </Grid>
                                    <Grid item xs={2}>
                                  <FormControl fullWidth variant="outlined">
                                      <InputLabel size='small' >Failure Status</InputLabel>
                                      <Select
                                          label="LabConfirm ID"
                                          value={searchFilters.failure}
                                          size='small'
                                          onChange={(e) => setSearchFilters({ ...searchFilters, failure : e.target.value , labPartner:'', extLabConfirmIdStatus : undefined})}
                                      >
                                          <MenuItem value={'R'}>Report Failure</MenuItem>
                                          <MenuItem value={'B'}>Booking Failure</MenuItem>
                                      </Select>
                                  </FormControl>
                              </Grid>
                              {localStorage?.userRole == 'DIAGGROUPADMIN'  &&  
                               <Grid item xs={2}>
            <Autocomplete
 options={labList}
 getOptionLabel={(option) => option.ccName}
 onChange={(event, newValue) => setSearchFilters({ ...searchFilters, hospitalName: newValue?.ccCode || '', labPartner: '' })}
 renderInput={(params) => (
   <TextField
     {...params}
     label="Collection Centre"
     variant="outlined"
     size="small"
     placeholder="Filter labs"
   />
 )}
 value={labList.find((lab) => lab.ccCode === searchFilters.hospitalName) || null}
            />
          </Grid>
                              }   
                                                   <Grid item xs={2}>
                                                   <Autocomplete
            options={eclinicData || []}
            getOptionLabel={(option) => option.eclinicName || ''}
            onChange={(event, newValue) => 
              setSearchFilters({ 
                ...searchFilters, 
                CHEId: newValue?.eclinicCode || '', 
                labPartner: '' 
              })
            }
            onInputChange={(event, value) => getEclinics(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Eclinics"
                variant="outlined"
                size="small"
                placeholder="Filter Eclinics"
              />
            )}
            value={
              eclinicData?.find((lab) => lab.eclinicCode === searchFilters.CHEId) || null
            }
            isOptionEqualToValue={(option, value) => option.eclinicCode === value?.eclinicName}
          />
          </Grid>
                                  <Grid item xs={2}>
                                  <TextField
                                      label="Consignment ID"
                                      variant="outlined"
                                      fullWidth
                                      value={searchFilters.consignmentId}
                                      size='small'
                                      onChange={(e) => setSearchFilters({ ...searchFilters, consignmentId: e.target.value, labPartner:'' })}
                                  />
                              </Grid>
                              <Grid item xs={2}>
                                  <FormControl fullWidth variant="outlined">
                                      {/* <Select
                                          label="Upload Status"
                                          value={searchFilters.sampleStatusId}
                                          size='small'
                                          onChange={(e) => setSearchFilters({ ...searchFilters, sampleStatusId: e.target.value , labPartner:'' })}
                                      >
                                              {statusList.map((status, index) => (
                <MenuItem key={status.id} value={status.id}>
                  {status.name}
                </MenuItem>
              ))}
                                      </Select> */}
                                      <Autocomplete
            options={statusList || []}
            getOptionLabel={(option) => option.name || ''}
            onChange={(event, newValue) => 
              setSearchFilters({ 
                ...searchFilters, 
                sampleStatusId: newValue?.id || '', 
                labPartner: '' 
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="SampleStatus"
                variant="outlined"
                size="small"
                placeholder="Select Status"
          
              />
            )}
            value={
              statusList?.find((lab) => lab.id === searchFilters.sampleStatusId) || null
            }
          //   isOptionEqualToValue={(option, value) => option.id === value?.name}
          />
                                  </FormControl>
                              </Grid>
                                                {/* <Grid item xs={2}>
                                                      <FormControl fullWidth variant="outlined">
                                                          <InputLabel size='small' >Source Chanel</InputLabel>
                                                          <Select
                                                              label="Source Chanel"
                                                              value={searchFilters.source}
                                                              size='small'
                                                              onChange={(e) => setSearchFilters({ ...searchFilters, source: e.target.value , labPartner:'' })}
                                                          >
                                                              <MenuItem value={'D2C'}>D2C</MenuItem>
                                                              <MenuItem value={'B2C'}>B2C</MenuItem>
                              
                                                      
                                                          </Select>
                                                      </FormControl>
                                                  </Grid> */}
                                                  <Grid item xs={2}>
                                                      <FormControl fullWidth variant="outlined">
                                                          <InputLabel size='small' >Category Type</InputLabel>
                                                          <Select
                                                              label="Category Type"
                                                              value={searchFilters.testType}
                                                              size='small'
                                                              onChange={(e) => setSearchFilters({ ...searchFilters, testType: e.target.value , labPartner:'' })}
                                                              ref={inputRef}
                                                              key={resetKey} 

>
                                                              <MenuItem value={1}>Pathology</MenuItem>
                                                              <MenuItem value={2}>Radiology</MenuItem>
                              
                                                      
                                                          </Select>
                                                      </FormControl>
                                                  </Grid>
                              <Button variant="contained" color="primary" onClick={handleSearch} sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                  Search
                              </Button>
          
                              <Button variant="contained" color="secondary"                 onClick={handleClear}
           sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                  Clear
                              </Button>
                              <Button variant="contained" color="primary" onClick={exportToExcel} sx={{ borderRadius: '1rem', width: '10rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                  Export Excel
                              </Button>
                          </Grid>
                      </Paper>
            <Box sx={{ width: '100%', mt: 2 }}>
            {/* Tabs Navigation */}
            <Tabs value={selectedTab} onChange={handleTabChange} >
            <Tab label="Received Orders" />
                <Tab label="Pending Orders" />
                <Tab label="Completed Orders" />
            </Tabs>
   
      {selectedTab != 0? <Box sx={{ p: 3 }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'orderId'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('orderId')}
                                >
                                    Order ID
                                </TableSortLabel>
                            </TableCell>
                               <TableCell>
                                                            <TableSortLabel
                                                                active={sort.sortBy === 'patientId'}
                                                                direction={sort.sortOrder}
                                                                onClick={() => handleSort('patientId')}
                                                            >
                                                                Patient ID
                                                            </TableSortLabel></TableCell>
                            <TableCell> <TableSortLabel
                                active={sort.sortBy === 'patientName'}
                                direction={sort.sortOrder}
                                onClick={() => handleSort('patientName')}
                            >
                                Patient
                            </TableSortLabel></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'hospitalName'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('hospitalName')}
                                >
                                    Diagnostic Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'sourceChannel'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('sourceChannel')}
                                >Revenue Source
                                </TableSortLabel></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'chehospitalName'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('chehospitalName')}
                                >eClinic Name
                                </TableSortLabel></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'chelocationName'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('chelocationName')}
                                >eClinic Location
                                </TableSortLabel></TableCell>
                            <TableCell>Sample Collection Status</TableCell>
                            <TableCell>Procedure Status</TableCell>
                            <TableCell>Report Upload</TableCell>
                            <TableCell>Report Sent</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'referredBy'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('referredBy')}
                                >Referred By
                                </TableSortLabel></TableCell>
                                {/* <TableCell>Lab Partner</TableCell> */}

                                <TableCell>Lab ConfirmID </TableCell>
                                <TableCell>LabConfirmID Status </TableCell>
                                <TableCell>Total Amount(INR)</TableCell>
                                <TableCell>
                                Consigment Id </TableCell>
                                <TableCell>
                                Consigment Date</TableCell>
                                <TableCell>
                                    
                                    Sample Status </TableCell>
                                <TableCell>
                                 View Log</TableCell>
                               
                            <TableCell>Lab Reports Upload</TableCell>
                            <TableCell>Receipt</TableCell>
                            <TableCell>Upload Invoice</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {getDataForPage(page).map((order) => ( */}
                        {getSortedData().map((order) => (
                            <TableRow key={order.orderId}>
                                <TableCell>
                                    <span variant="text" style={{ color: 'blue' }} size="small" onClick={() => handleOrderClick(order)}>
                                        {order.orderId}
                                    </span>
                                </TableCell>
                                <TableCell>{order.patientId}</TableCell>
                                <TableCell>{order.patientName}</TableCell>
                                <TableCell>{order.hospitalName}</TableCell>
                                <TableCell>{order.sourceChannel}</TableCell>
                                <TableCell>{order.chehospitalName}</TableCell>
                                <TableCell>{order.chelocationName}</TableCell>
                                <TableCell>
                                    <Button variant="text" color="primary" size="small" onClick={() => handleCollectionStatus(order)}>
                                        {getCollectionStatus(order.collectionStatus)}
                                    </Button>
                                </TableCell>
                                  <TableCell>
                                                                   {editProcedureStatus === order.orderId ? (
                                                                       <ProcedureStatusDropdown
                                                                           currentStatus={order.procedureStatus}
                                                                           orderId={order.orderId}
                                                                           updateOrderStatus={updateOrderStatus}
                                                                       />
                                                                   ) : (
                                                                       <span
                                                                           style={{ cursor: 'pointer' }}
                                                                           onClick={() => togglePSEditMode(order)}
                                                                       >
                                                                           {order.procedureStatus === 2
                                                                               ? 'Completed'
                                                                               : order.procedureStatus === 1
                                                                                   ? 'Pending'
                                                                                   : order.procedureStatus === 4
                                                                                       ? 'Cancelled'
                                                                                       : order.procedureStatus}
                                                                       </span>
                                                                   )}
                                                               </TableCell>
                                <TableCell>
                                    {editReportsUpload === order.orderId ? (
                                        <ReportUploadStatusDropdown
                                            currentStatus={order.status}
                                            orderId={order.orderId}
                                            order={order}

                                            updateOrderStatus={updateOrderStatus}
                                        />
                                    ) : (
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => toggleRUEditMode(order.orderId,order)}
                                        >
                                            {order.status === 2
                                                ? 'Uploaded'
                                                : order.status === 1
                                                    ? 'Pending'
                                                    : order.status === 0 ? 'Cancelled' : order.status}
                                        </span>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <div style={{padding:'8px'}}>
                                        <img  src={order.reportSend == 0 ? reportNotSent :reportSent} alt="View"/></div>
                                </TableCell>
                                <TableCell>{order.referredBy}</TableCell>
                                {order?.extLabConfirmId ? <TableCell>{order.extLabConfirmId}</TableCell>:    <TableCell>
                                    <Button onClick={() => manualUploadValidation(order)}>
                                        <img src={ uploadIcon}     alt="Upload" />
                                    </Button>
                                </TableCell>}
                             
                                <TableCell>{order.extLabConfirmIdStatus == 'M' ? 'Manual' : order.extLabConfirmId ? 'Auto' : ''}</TableCell>
                                <TableCell>{Number(order.paidAmount)?.toFixed(2)}</TableCell>
                                <TableCell>{order.consignmentId}</TableCell>
                                <TableCell>{order.consignmentDate ? convertUTCToLocal(order.consignmentDate,"MM/DD/YYYY hh:mm:ss") : ''}</TableCell>
                                <TableCell><span style={{ color: 'blue' }}>{order.sampleStatus !== 'Unknown Status' && order.sampleStatus}</span></TableCell>

                                <TableCell>
                                {order.consignmentId &&<Button onClick={() => handleCancelView(true,order.orderId)}>
                                        <img src={eyeIcon} alt="View" />
                                    </Button>}
                                </TableCell>

                                
                                <TableCell>
                                    <Button onClick={() => handleUploadClick(order)}> 
                                        <img src={ order.reportAvailable == 0 ? uploadIcon :uploadIcon2}     alt="Upload" />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleReceiptClick(order)}>
                                        <img src={eyeIcon} alt="View" />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <div style={{ display: 'flex' }}>
                                        <Button onClick={() => handleUploadInVoiceClick(order)}>
                                            <img src={uploadIcon} alt="Upload" />
                                        </Button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={(e) => uploadInvoice(e, order)}
                                        />
                                        {order?.reportInvoiceDocName &&
                                            <Button onClick={() => handleViewClick(order)}>
                                                <img src={eyeIcon} alt="View" />
                                            </Button>
                                        }
                                    </div>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {orderDetails?.length > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                    <Pagination
                        count={Math.ceil(orderDetails.length / itemsPerPage)}
                        page={page}
                        color="secondary"
                        onChange={handlePageChange}
                    />
                </Box>
            )}
</Box>:
<Box sx={{ p: 3 }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'orderId'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('orderId')}
                                >
                                    Order ID
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                            <TableSortLabel
                                                                active={sort.sortBy === 'patientId'}
                                                                direction={sort.sortOrder}
                                                                onClick={() => handleSort('patientId')}
                                                            >
                                                                Patient ID
                                                            </TableSortLabel></TableCell>
                            <TableCell> <TableSortLabel
                                active={sort.sortBy === 'patientName'}
                                direction={sort.sortOrder}
                                onClick={() => handleSort('patientName')}
                            >
                                Patient
                            </TableSortLabel></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'hospitalName'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('hospitalName')}
                                >
                                    Diagnostic Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'sourceChannel'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('sourceChannel')}
                                >Revenue Source
                                </TableSortLabel></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'chehospitalName'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('chehospitalName')}
                                >eClinic Name
                                </TableSortLabel></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sort.sortBy === 'chelocationName'}
                                    direction={sort.sortOrder}
                                    onClick={() => handleSort('chelocationName')}
                                >eClinic Location
                                </TableSortLabel></TableCell>
                                <TableCell></TableCell>
    
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {getDataForPage(page).map((order) => ( */}
                        {getSortedData().map((order) => (
                            <TableRow key={order.orderId}>
                                <TableCell>
                                <span variant="text" style={{ color: 'blue' }} size="small" onClick={() => handleOrderClick(order)}>
                                        {order.orderId}
                                    </span>
                                </TableCell>
                                <TableCell>{order.patientId}</TableCell>

                                <TableCell>{order.patientName}</TableCell>
                                <TableCell>{order.hospitalName}</TableCell>
                                <TableCell>{order.sourceChannel}</TableCell>
                                <TableCell>{order.chehospitalName}</TableCell>
                                <TableCell>{order.chelocationName}</TableCell>
                                <Button variant="contained" color="primary" onClick={()=>confirmOrder(order)} sx={{ borderRadius: '1rem', width: '5rem',marginTop:'1rem'}}>
                        Confirm
    </Button>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {orderDetails?.length > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                    <Pagination
                        count={Math.ceil(orderDetails.length / itemsPerPage)}
                        page={page}
                        color="secondary"
                        onChange={handlePageChange}
                    />
                </Box>
            )}
</Box>
}
</Box>
            <Modal open={modalOpen} onClose={handleCloseModal}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '160vh', width: '160vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Upload Report</Typography>
                            }
                            action={
                                <IconButton onClick={handleCloseModal}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            <div style={{ marginBottom: '20px', borderBottom: '1px solid #e0e0e0', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Patient:
                                        </Typography>
                                        <Typography variant="body1">
                                            {`${patientLabTestsOrderDetails?.[0]?.patientName} / ${patientLabTestsOrderDetails?.[0]?.patientGender} / (${patientLabTestsOrderDetails?.[0]?.patientId})`}
                                            {/* ${patientLabTestsOrderDetails?.[0].patientAge} Years */}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Mobile Number:
                                        </Typography>
                                        <Typography variant="body1">
                                            {patientLabTestsOrderDetails?.[0]?.patientMobile}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Order ID:
                                        </Typography>
                                        <Typography variant="body1">
                                            {selectedOrder?.orderId}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Requested Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formateDateToDDMMYYYYHHMM(patientLabTestsOrderDetails?.[0]?.preferDateTime != null ? patientLabTestsOrderDetails[0].preferDateTime : patientLabTestsOrderDetails?.[0]?.collectionSampleDate)}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Confirmed Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formateDateToDDMMYYYYHHMM(patientLabTestsOrderDetails?.[0]?.confirmDateTime)}
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginBottom: '20px', borderBottom: '1px solid #e0e0e0', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Home Sample Collection Address
                                        </Typography>
                                        <Typography variant="body1">
                                            {selectedOrder?.homeSamplePatientAddress}
                                        </Typography>
                                    </div>
                                </div>
                                <Button variant="contained" color="primary" onClick={() => setUploadReportsModalOpen(true)}>
                                    Upload Report
                                </Button>
                            </div>
                            <div>
                                <Typography variant="h6" sx={{ marginBottom: '1rem', fontSize: '16px', fontWeight: 500 }}>Lab Report</Typography>
                                <Grid container>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Lab Tests</TableCell>
                                                    <TableCell>File Type</TableCell>
                                                    <TableCell>View</TableCell>
                                                    <TableCell>Download</TableCell>
                                                    <TableCell>Action</TableCell>
                                                    <TableCell>Uploaded</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {labTestsOrderReports.map((rec) => (
                                                    <TableRow>
                                                        <TableCell>{rec?.labTestName}</TableCell>
                                                        <TableCell>{rec?.type?.toUpperCase()}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => handleViewClick2(rec)}>
                                                                <img src={eyeIcon} alt="View" />
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => handleDownloadClick(rec)}>
                                                                <img src={downloadIcon} alt="Download" />
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => handleDeleteClick(rec)}>
                                                                <img src={deleteIcon} alt="Delete" />
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell>
                                                           {rec.createdByName}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>

            <Modal open={invoiceModalOpen} onClose={handleCloseInvoiceModal}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '160vh', width: '160vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Invoice</Typography>
                            }
                            action={
                                <IconButton onClick={handleCloseInvoiceModal}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            <div style={{ marginBottom: '20px', borderBottom: '1px solid #e0e0e0', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Patient:
                                        </Typography>
                                        <Typography variant="body1">
                                            {`${patientLabTestsOrderDetails?.[0]?.patientName} / ${patientLabTestsOrderDetails?.[0]?.patientGender} / (${patientLabTestsOrderDetails?.[0]?.patientId})`}
                                            {/* ${patientLabTestsOrderDetails?.[0].patientAge} Years */}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Mobile Number:
                                        </Typography>
                                        <Typography variant="body1">
                                            {patientLabTestsOrderDetails?.[0]?.patientMobile}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Order ID:
                                        </Typography>
                                        <Typography variant="body1">
                                            {selectedOrder?.orderId}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Requested Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formateDateToDDMMYYYYHHMM(patientLabTestsOrderDetails?.[0]?.preferDateTime != null ? patientLabTestsOrderDetails[0].preferDateTime : patientLabTestsOrderDetails?.[0]?.collectionSampleDate)}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Confirmed Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formateDateToDDMMYYYYHHMM(patientLabTestsOrderDetails?.[0]?.confirmDateTime)}
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Tests</Typography>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Total Amount (INR)</Typography>
                            </div>

                            <Divider />

                            {patientLabTestsOrderDetails?.map((test, index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                    <Typography variant="body1">{test?.labTestName}</Typography>
                                    <Typography variant="body1">{test?.amount?.toFixed(2)}</Typography>
                                </div>
                            ))}

                            <Divider />
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Total (INR)</Typography>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>{calculateTotalPrice().toFixed(2)}</Typography>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Curebay Discount (INR)</Typography>
                                <Typography variant="body1">- {calculateTotalDiscount().toFixed(2)}</Typography>

                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Payable Amount (INR)</Typography>
                                <Typography variant="body1"> {calculatePayableAmount().toFixed(2)}</Typography>
                            </div>
                            <div>
                            </div>
                            <div style={{ marginTop: '20px', textAlign: 'right' }}>
                                <IconButton onClick={handleDownload} style={{ color: '#3D65FF' }}>
                                    <span style={{ cursor: 'pointer', fontSize: '1rem' }}>Download</span>
                                </IconButton>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>
            <Modal open={uploadReportsModalOpen} onClose={uploadReportsModalClose}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '100vh', width: '100vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Upload Report</Typography>
                            }
                            action={
                                <IconButton onClick={uploadReportsModalClose}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Autocomplete
                                        value={selectedLabReport}
                                        onChange={handleAutocompleteChange}
                                        inputValue={typedLabReport}
                                        onInputChange={handleInputChange}
                                        options={patientLabTestsOrderDetails.map((report) => report.labTestName)}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select or type Lab Report"
                                                variant="outlined"
                                                size="small"
                                                style={{ width: '30rem', maxWidth: '30rem' }}
                                            />
                                        )}
                                    />
                                    <input type="file" onChange={handleFileChange} multiple />
                                </div>
                            </div>


                            {selectedFiles.length > 0 && (
                                <div style={{ marginTop: '20px' }}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '10px' }}>Selected Files:</Typography>
                                    <ul>
                                        {selectedFiles.map((file, index) => (
                                            <li key={index}>
                                                {file.name}
                                                <IconButton onClick={() => handleRemoveFile(index)} style={{ color: 'red' }}>  <CloseIcon /></IconButton>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                <Button variant="contained" color="primary" onClick={() => handleSave(selectedLabReport)} >Save</Button>
                                <Button variant="outlined" color="secondary" style={{ marginLeft: '10px' }} onClick={uploadReportsModalClose}>Cancel</Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>
            <Modal open={viewModal} onClose={() => setViewModal(false)}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '100vh', width: '100vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>  {viewModalData?.reportInvoiceDocName ? 'View Invoice' : 'View Report'}</Typography>
                            }
                            action={
                                <IconButton onClick={() => setViewModal(false)}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            {isPDF ? (
                                <iframe
                                    title="PDF Viewer"
                                    src={`${filePath}${viewModalData?.photoName || viewModalData?.reportInvoiceDocName}`}
                                    width="100%"
                                    height="100%"
                                    style={{ border: 'none' }}
                                ></iframe>
                            ) : (
                                <img
                                    src={`${filePath}${viewModalData?.photoName || viewModalData?.reportInvoiceDocName}`}
                                    alt={'Lab Test'}
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            )}
                        </CardContent>
                    </Card>
                </div>
            </Modal>

            <Modal open={sampleCollectionModal} onClose={onSampleCollectionClose}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '160vh', width: '160vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Sample Collection</Typography>
                            }
                            action={
                                <IconButton onClick={onSampleCollectionClose}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            <div style={{ marginBottom: '20px', borderBottom: '1px solid #e0e0e0', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Patient:
                                        </Typography>
                                        <Typography variant="body1">
                                            {`${sampleSelectionRecord?.patientName} / ${sampleSelectionRecord?.patientGender} / (${sampleSelectionRecord?.patientId})`}
                                            {/* ${patientLabTestsOrderDetails?.[0].patientAge} Years */}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Mobile Number:
                                        </Typography>
                                        <Typography variant="body1">
                                            {sampleSelectionRecord?.patientMobile}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Order ID:
                                        </Typography>
                                        <Typography variant="body1">
                                            {sampleSelectionRecord?.orderId}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Requested Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formateDateToDDMMYYYYHHMM(sampleSelectionRecord?.preferDateTime != null ? sampleSelectionRecord.preferDateTime : sampleSelectionRecord?.collectionSampleDate)}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Confirmed Date & Time:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formateDateToDDMMYYYYHHMM(sampleSelectionRecord?.confirmDateTime)}
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginBottom: '20px', borderBottom: '1px solid #e0e0e0', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, marginRight: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Home Sample Collection Address
                                        </Typography>
                                        <Typography variant="body1">
                                            {sampleSelectionRecord?.homeSamplePatientAddress}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={3}>
                                        <CustomDateTimePicker
                                            variant="outlined"
                                            fullWidth
                                            label="Home Sample Collection Date & Time"
                                            selectedDateTime={formData?.homeSampleCollectionDateTime}
                                            onChange={(dateTime) =>
                                                handleFieldChange('homeSampleCollectionDateTime', dateTime)
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CustomDateTimePicker
                                            variant="outlined"
                                            fullWidth
                                            label="Test at Lab Date & Time"
                                            selectedDateTime={formData?.labTestDateTime}
                                            onChange={(dateTime) =>
                                                handleFieldChange('labTestDateTime', dateTime)
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label="Technicians Assigned"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={formData?.techniciansAssigned || ''}
                                            onChange={(e) => handleFieldChange('techniciansAssigned', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label="Contact Number"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            type="text"
                                            value={formData?.contactNumber || ''}
                                            onChange={(e) => handleFieldChange('contactNumber', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel size="small" >Status</InputLabel>
                                            <Select
                                                label="Status"
                                                size="small"
                                                value={formData?.status !== undefined ? formData?.status : ''}
                                                onChange={(e) => handleFieldChange('status', e.target.value)}
                                            >
                                                <MenuItem value="1">Order Confirmed</MenuItem>
                                                <MenuItem value="3">Technician Assigned</MenuItem>
                                                <MenuItem value="4">Sample Collected</MenuItem>
                                                <MenuItem value="5">Cancelled by Patient</MenuItem>
                                                <MenuItem value="6">Cancelled by Diagnostic</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                        <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                <Button variant="contained" color="primary" onClick={onSampleCollectionSave}>Save</Button>
                                <Button variant="outlined" color="secondary" style={{ marginLeft: '10px' }} onClick={onSampleCollectionClose}>Cancel</Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Modal>
            <Modal open={viewLabTests} onClose={onCloseLabTestsView}>
                <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '100vh', width: '100vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Ordered Lab Tests</Typography>
                            }
                            action={
                                <IconButton onClick={onCloseLabTestsView}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />
                        <Divider />
                        <CardContent>
                            <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 500, marginBottom: '16px' }}>
                                Lab Test Details
                            </Typography>
                            <Divider style={{ margin: '8px 0' }} />
                            {labTestDetails?.patientLabTestsOrderDetailsList?.map((test, index) => (
                                <div key={index}>
                                    <Typography variant="body1">{test?.labTestName}</Typography>
                                    <Divider style={{ margin: '8px 0' }} />
                                </div>
                            ))}
                        </CardContent>
                    </Card>
                    
                </div>

                    </div>
            </Modal>
            <Modal open={patientSearch}
                onClose={handleClosepatientSearch} >
                <PatientSearch onClose={handleClosepatientSearch} />
            </Modal>
            <Modal open={logView} onClose={() => handleCancelView(false)}>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Card style={{ maxWidth: '160vh', width: '60vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
            <CardHeader
                title={<Typography variant="h6" style={{ fontSize: '18px', fontWeight: 500 }}>Order ID : {logData?.[0]?.orderId}</Typography>}
                action={
                    <IconButton onClick={() => handleCancelView(false)}>
                        <CloseIcon />
                    </IconButton>
                }
                style={{
                    backgroundColor: '#f5f5f5',
                    borderBottom: '1px solid #e0e0e0',
                }}
            />
            <Divider />
            <CardContent>
                <div>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>Sample Status</Typography>
                    <Divider />
                    {Array.isArray(logData) && logData.length > 0 ? (
                        logData.map((user, i) => (
                            <div key={i} style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', padding: '10px', borderBottom: '1px solid #e0e0e0' }}>
                                <Typography variant="body1">
                                    <b>Sample Status:</b> <span style={{ color: '#1976D2' }}>{user.statusName}</span>
                                </Typography>
                                <Typography variant="body1">
                                <b>Date:</b> {user.reachedDate ? convertUTCToLocal(user.reachedDate,"MM/DD/YYYY hh:mm:ss"):''}
                                </Typography>
                            </div>
                        ))
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>No logs available.</div>
                    )}
                </div>
            </CardContent>
        </Card>
    </div>
</Modal>

<Modal open={bookiIdModal} onClose={onCloseLabTestsView}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ maxWidth: '100vh', width: '100vh', maxHeight: '80vh', overflowY: 'auto', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '8px' }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" style={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                }}>Manual Booking</Typography>
                            }
                            action={
                                <IconButton onClick={onCloseLabTestsView}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        />

      <Grid item xs={2} style={{marginLeft:'30px',marginRight:'30px',marginTop:'30px'}}>
                                        <TextField
                                            label="Enter Bookin ID"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            type="number"
                                        value={externalLabConfirmId}
                                        onKeyDown={handleKeyDown}

                                            onChange={(e) => handleFieldChange2('externalLabConfirmId', e.target.value)}
                                        />
                                    </Grid>       

                                       <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                <Button variant="contained" color="primary" onClick={manualBookingSave}>Save</Button>
                                <Button variant="outlined" color="secondary" style={{ marginLeft: '10px' }} onClick={onCloseLabTestsView}>Cancel</Button>
                            </div>
                        </CardContent>              
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default CompletedOrders;
